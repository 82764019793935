<template>
  <div id="app">
    <router-view class="router-view" v-slot="{ Component }">
      <transition :name="transitionName">
        <component :is="Component" />
      </transition>
    </router-view>
  </div>
</template>

<script>
import { reactive, toRefs } from 'vue'
import { useRouter } from 'vue-router'
export default {
  name: 'app',
  setup() {
    const router = useRouter()
    const state = reactive({
      transitionName: 'slide-left'
    })
    window.onload = function() {
			document.addEventListener('touchstart', function(event) {
				if (event.touches.length > 1) {
					event.preventDefault()
				}
			})
			document.addEventListener('gesturestart', function(event) {
				event.preventDefault()
			})
		}
    router.beforeEach((to, from) => {
      if (to.meta.index > from.meta.index) {
        state.transitionName = 'slide-left' // 向左滑动
      } else if (to.meta.index < from.meta.index) {
        // 由次级到主级
        state.transitionName = 'slide-right'
      } else {
        state.transitionName = ''   // 同级无过渡效果
      }
    })

    return {
      ...toRefs(state)
    }
  }
}
</script>

<style lang="less">
html,
body {
  height: 100%;
  overflow-x: hidden;
  // overflow-y: scroll;
  background: #01040D;
  
  .van-popover--light .van-popover__arrow {
    color: #c8a855;
  }
}

div {
  color: #fff;
  font-size: 16px;
  line-height: 1.5;
  font-family: PingFangSC-Regular, PingFang SC;
}

img {
  margin: 0;
  padding: 0;
}
// 横短线
.horizontal_line {
  width: 30px;
  height: 4px;
  background-color: #4B6CFC;
  border-radius: 2px;
  margin-top: 5px;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // text-align: center;
  color: #2c3e50;
  // margin-top: 60px;
  background: #01040D;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.router-view {
  width: 100%;
  height: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: 0 auto;
  -webkit-overflow-scrolling: touch;
}

.slide-right-enter-active,
.slide-right-leave-active,
.slide-left-enter-active,
.slide-left-leave-active {
  height: 100%;
  will-change: transform;
  transition: all 200ms;
  position: absolute;
  backface-visibility: hidden;
}

.slide-right-enter {
  opacity: 0;
  transform: translate3d(-100%, 0, 0);
}

.slide-right-leave-active {
  opacity: 0;
  transform: translate3d(100%, 0, 0);
}

.slide-left-enter {
  opacity: 0;
  transform: translate3d(100%, 0, 0);
}

.slide-left-leave-active {
  opacity: 0;
  transform: translate3d(-100%, 0, 0);
}
</style>
