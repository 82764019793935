const routes = () => {
    if (process.env.VUE_APP_PRO_TYPE == 'monitor') {
        return [
            {
                path: '/',
                redirect: '/report'
            },
            {
                path: '/report/:id?',
                name: 'report',
                component: () => import(/* webpackChunkName: "report" */ '@/presentation/Report.vue'),
                meta: {
                    index: 1
                }
            },
            {
                path: '/data/:id?',
                name: 'data',
                component: () => import(/* webpackChunkName: "data" */ '@/presentation/Data.vue'),
                meta: {
                    index: 1
                }
            },
            {
                path: '/my',
                name: 'my',
                component: () => import(/* webpackChunkName: "my" */ '@/presentation/My.vue'),
                meta: {
                    index: 1
                }
            },
            {
                path: '/knowledge',
                name: 'knowledge',
                component: () => import(/* webpackChunkName: "knowledge" */ '@/presentation/Knowledge.vue'),
                meta: {
                    index: 1
                }
            },
            {
                path: '/knowledgeDetail/:type',
                name: 'knowledgeDetail',
                component: () => import(/* webpackChunkName: "knowledgeDetail" */ '@/presentation/KnowledgeDetail.vue'),
                meta: {
                    index: 1
                }
            },
        ]
    } else {
        return [
            {
                path: '/',
                redirect: '/home'
            },
            {
                path: '/home',
                name: 'home',
                component: () => import(/* webpackChunkName: "home" */ '@/views/Home.vue'),
                meta: {
                    index: 1
                }
            },
            {
                path: '/service/:type',
                name: 'service',
                component: () => import(/* webpackChunkName: "service" */ '@/views/Service.vue'),
                meta: {
                    index: 2
                }
            },
            {
                path: '/detail/:type?/:id?',
                name: 'detail',
                component: () => import(/* webpackChunkName: "detail" */ '@/views/Detail.vue'),
                meta: {
                    index: 3
                }
            },
            {
                path: '/improve',
                name: 'improve',
                component: () => import(/* webpackChunkName: "improve" */ '@/views/Improve.vue'),
                meta: {
                    index: 1
                }
            },
            {
                path: '/contact',
                name: 'contact',
                component: () => import(/* webpackChunkName: "contact" */ '@/views/Contact.vue'),
                meta: {
                    index: 2
                }
            },
        ]
    }
}

export default {
    routes: routes()
}