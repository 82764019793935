import { createRouter, createWebHistory, createWebHashHistory } from 'vue-router'
import monitor from "../common/point-monitor.js";
import routes from "./routes.js"

const router = createRouter({
  history: createWebHashHistory(), // hash模式：createWebHashHistory，history模式：createWebHistory
  routes: routes.routes
})

// 路由前置守卫
router.beforeEach((to, from, next) => {
  // console.log("跳转之前",to)
  // console.log("跳转之前",from)
  // 有token就表示已经登录
  // 想要进入购物车页面，必须有登录标识token
  // console.log('to:', to)
  // console.log('from:', from)
  // let token = localStorage.getItem('token')
  // if (to.path == '/cart') {
  //     // 此时必须要有token
  //     if (token) {
  //         next(); // next()去到to所对应的路由界面
  //     } else {
  //         Vue.prototype.$toast('请先登录');
  //         // 定时器
  //         setTimeout(() => {
  //             next("/user"); // 强制去到"/user"所对应的路由界面
  //         }, 1000);
  //     }
  // } else {
  //     // 如果不是去往购物车的路由，则直接通过守卫，去到to所对应的路由界面
  //     next()
  // }
 monitor.routerMonitor(from)
  next()
})

router.afterEach((to,from,next) => {
  // console.log("跳转之后",to)
  // console.log("跳转之后",from)
  // startTime = new Date().getTime()
})

export default router