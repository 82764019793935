
const routerParam = () => {
    // console.log(window.navigator.userAgent.toLowerCase())
    // console.log(window.webkit)
    let hrefArr = window.location.href.split("?")
    let arr = []
    if (hrefArr.length > 1) {
        arr = hrefArr[1].split("&")
    }
    let param = {
        lang: "zh",
        // type: "android"
    }
    arr.forEach(function (item) {
        item = item.split("=")
        param[item[0]] = item[1]
    })
    return param
}

const routerUrl = param => {
    let arr = []
    for (const key in param) {
        arr.push (key+"="+param[key])
    }
    return arr.join("&")
}


const routerGoto = (url,router=null) => {
    let param = routerParam()
    let hrefParam = routerUrl(param)
    let href = window.location.href.split("#")[0] + "#" + url + "?" + hrefParam
    // console.log(window.location)
    console.log("地址：",href)
    if (param.type && param.type === "android") {
        window.NativeToJS.goTo(href)
    } else if (param.type && param.type === "ios") {
        console.log("ios")
        window.webkit.messageHandlers.goTo.postMessage(href)
    } else {
        router&&router.push({ path: url })
    }
}

const routerGoback = () => {
    let param = routerParam()
    if (param.type && param.type === "android") {
        window.NativeToJS.goBack()
    } else if (param.type && param.type === "ios") {
        console.log("ios")
        window.webkit.messageHandlers.goBack.postMessage(null)
    } else {
        history.back()
    }
}

export default {
    routerParam,
    routerGoto,
    routerGoback
}