import { createI18n } from 'vue-i18n'
import webviewRouter from '@/common/webviewRouter.js'
import en from './en.js'
import zh from './zh.js'
import ja from './ja.js'

const messages = {
	en,
	zh,
	ja
}
let lang = webviewRouter.routerParam()
// console.log(lang)
const language = (lang.lang || navigator.language.split("-")[0] || "zh").toLocaleLowerCase()
console.log("当前语言：",language)
const i18n = createI18n({
    legacy: false,          // you must set `false`, to use Composition API
    locale: language,
    fallbackLocale: "en",
    messages
})

export default i18n