import request from "./request.js"
// 埋点--记录页面停留时间
export function monitorPage(param) {
    return request.post('/web-api/antiSnore/pu/source/set',param)
}
// 埋点--记录点击事件
export function monitorBtn(param) {
    return request.post('/web-api/antiSnore/pu/source/set',param)
}
// 保留联系方式
export function contactSubmit(param) {
    return request.post('/app-api/antiSnore/other/doAction',{
        methodName: "feedbackRecord",
        ...param
    })
}

// 实时数据
export function getData(param) {
    return request({
        method: "get",
        url: '/bksensor-api/openApi/getRealTimeData',
        params: param
    })
}

// 监测报告
export function getReport(param) {
    return request({
        method: "get",
        url: '/bksensor-api/openApi/getSleepStag',
        params: param
    })
}

// 获取睡眠记录
export function getSleepRecord(param) {
    return request({
        method: "get",
        url: '/bksensor-api/openApi/getSleepRecord',
        params: param
    })
}

// 获取周/月数据
export function getSensorReport(param) {
    return request({
        method: "get",
        url: '/bksensor-api/openApi/getSensorReport',
        params: param
    })
}