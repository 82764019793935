import routerList from "./routerList.js";
import {monitorPage} from "../utils/service.js";

// 点击事件
const clickMonitor = (fun, data = {}) => {
    new Promise((resolve, reject) => {
        // 请求
        setTimeout(() => {
            resolve(data)
        }, 10)
        // console.log(data)
    }).then(res => {
        console.log("编号：", res)
    }).catch(e => {
        console.log("失败", e)
    })
    fun(data.item)
}
// 路由跳转
let startTime = new Date().getTime()
const routerMonitor = (from) => {
    new Promise((resolve, reject) => {
        // return
        // 请求
        let obj = {}
        let fromName = from.name
        let curryTime = new Date().getTime()
        let time = curryTime - startTime
        if (!!fromName) {
            obj.page = routerList[fromName] || fromName
            obj.time = time
            console.log("页面停留：",obj)
            // 发送信息
            // monitorPage({
            //     customer_id: 0,
            //     fun_where: "A-0001",
            //     fun_where_url: "",
            //     fun_name: "B-0009",
            //     fun_type: 0,
            //     stop_time: time,
            //     source: "",
            //     device_type: "web"
            // }).then(res=>{
            //     resolve(res)
            // }).catch(e=> {
            //     reject(e)
            // })
        }
        startTime = new Date().getTime()
    }).then(res => {
        console.log("返回数据:", res)
    }).catch(e => {
        console.log("请求失败", e)
    })
}


export default {
    clickMonitor,
    routerMonitor
}