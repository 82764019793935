import { getData, getReport, getSensorReport } from "@/utils/service.js"
import util from "@/utils/util.js"
let timer = null
let timer2 = null
const list = [
    { color: "#9E58FD", value: "深睡", type: "sum4" },
    { color: "#5ECCFE", value: "浅睡", type: "sum3" },
    { color: "#FB7D6F", value: "梦期", type: "sum2" },
    { color: "#61FCE3", value: "清醒", type: "sum1" },
]
export default {
    changeTabbar({ }, param) {
        timer && clearInterval(timer)
        timer2 && clearInterval(timer2)
        let { router, path } = param
        router.push({ path })
    },
    getRealData({ state, commit }, id) {
        // let deviceName = id ? id : state.deviceName
        let deviceName = "S02S1_KY01"
        timer && clearInterval(timer)
        timer = setInterval(() => {
            let heartData = [...state.heartData]
            let breatheData = [...state.breatheData]
            getData({ deviceName }).then(res => {
                let { data } = res
                let heartValue = data[0] || "--"
                let breatheValue = data[1] || "--"
                if (heartData.length >= 2500 || breatheData.length >= 2500) {
                    heartData.shift()
                    breatheData.shift()
                }
                heartData.push(data[0] || null)
                breatheData.push(data[1] || null)
                commit("changeData", {
                    heartValue,
                    breatheValue,
                    heartData,
                    breatheData
                })
            }).catch(e => {
                console.error("报错：", e)
                heartData.push(null)
                breatheData.push(null)
                commit("changeData", {
                    heartValue: "--",
                    breatheValue: "--",
                    heartData,
                    breatheData,
                    // deviceName
                })
                // timer && clearInterval(timer)
            })
        }, 3000)
    },
    getRealData2({ state, commit }, id) {
        // let deviceName = id ? id : state.deviceName
        let deviceName = "S02S1_KY02"
        timer2 && clearInterval(timer2)
        timer2 = setInterval(() => {
            let heartData1 = [...state.heartData1]
            let breatheData1 = [...state.breatheData1]
            getData({ deviceName }).then(res => {
                let { data } = res
                let heartValue1 = data[0] || "--"
                let breatheValue1 = data[1] || "--"
                if (heartData1.length >= 2500 || breatheData1.length >= 2500) {
                    heartData1.shift()
                    breatheData1.shift()
                }
                heartData1.push(data[0] || null)
                breatheData1.push(data[1] || null)
                commit("changeData", {
                    heartValue1,
                    breatheValue1,
                    heartData1,
                    breatheData1
                })
            }).catch(e => {
                console.error("报错：", e)
                heartData1.push(null)
                breatheData1.push(null)
                commit("changeData", {
                    heartValue1: "--",
                    breatheValue1: "--",
                    heartData1,
                    breatheData1,
                    // deviceName
                })
                // timer2 && clearInterval(timer2)
            })
        }, 3000)
    },
    // 日报数据初始化
    initReportData({ state, commit }, deviceName) {
        commit("changeData", {
            bedtime: "--",
            wakeup: "--",
            expValue: "--",
            sleepTime: "--",
            heartUpper: "--",
            heartLower: "--",
            breatheUpper: "--",
            breatheLower: "--",
            breatheChartData: [],
            heartChartData: [],
            exponentChart: [],
            sumTime: {
                sum1: 0,
                sum2: 0,
                sum3: 0,
                sum4: 0
            },
            // deviceName: deviceName
        })
    },
    // 获取日报数据
    getReportData({ state, commit, dispatch }, deviceName = null) {
        deviceName = deviceName ? deviceName : state.deviceName
        dispatch("initReportData", deviceName)
        let param = {
            deviceName: deviceName,
            deviceDate: state.currtyData && state.currtyData.replace(/\./g, "").replace(/\-/g, "")
        }
        getReport(param).then(res => {
            // console.log(res)
            if (res.data) {
                let sumObj = {
                    sum1: 0,
                    sum2: 0,
                    sum3: 0,
                    sum4: 0
                }
                // Toast.success('数据加载成功');
                let { breathList, endSleepTime, heartList, minHeartRate, minBreathRate, sleepExponent, startSleepTime, maxHeartRate, maxBreathRate, sleepMap, deviceDate } = res.data
                // let objKey = Object.keys(sleepMap)
                // let objValue = Object.values(sleepMap)
                let y = deviceDate.substring(0,4)
                let m = deviceDate.substring(4,6)
                let d = deviceDate.substring(6,8)
                let arr = [0, 3.5, 2.5, 1.5, 0.5]
                let objValue = []
                for (const key in sleepMap) {
                    if (Object.hasOwnProperty.call(sleepMap, key)) {
                        const element = sleepMap[key];
                        let arr2 = [key, arr[element]]
                        sumObj["sum" + element] += 1
                        objValue.push(arr2)
                    }
                }
                commit("changeData", {
                    currtyData: `${y}-${m}-${d}`,
                    codeTime: startSleepTime.split(" ")[0],
                    bedtime: startSleepTime.split(" ")[1],
                    wakeup: endSleepTime.split(" ")[1],
                    expValue: sleepExponent,
                    sleepTime: (new Date(endSleepTime.replace(/\-/g, "/")) - new Date(startSleepTime.replace(/\-/g, "/"))) / 1000,
                    heartUpper: maxHeartRate,
                    heartLower: minHeartRate,
                    breatheUpper: maxBreathRate,
                    breatheLower: minBreathRate,
                    breatheChartData: breathList,
                    heartChartData: heartList,
                    exponentChart: objValue,
                    sumTime: sumObj
                })
            } else {
                dispatch("initReportData", deviceName)
            }
        }).catch(e => {
            console.error("报错：", e)
            dispatch("initReportData", deviceName)
        })
    },
    // 获取周/月报数据
    getReportWeek({ state, commit, dispatch }, obj) {
        let emptyArr = []
        for (let k = 0; k < list.length; k++) {
            const ele = list[k];
            let obj = {
                name: ele.value,
                color: ele.color,
                type: 'bar',
                stack: 'Ad',
                // barWidth: 10,
                data: [],
                markLine: {
                    data: []
                }
            }
            emptyArr.push(obj)
        }
        commit("changeData", {
            seriesArr: emptyArr,
            avgTime: {
                sum1: 0,
                sum2: 0,
                sum3: 0,
                sum4: 0
            },
            sleepScoreAvg: 0,
            sleepTimeAvg: 0,
        })
        let deviceName = obj.deviceName ? obj.deviceName : state.deviceName
        let weekRing = obj.weekRing
        let param = {
            deviceName: deviceName,
            startDate: weekRing.start && weekRing.start.replace(/\./g, ""),
            endDate: weekRing.end && weekRing.end.replace(/\./g, "")
        }
        getSensorReport(param).then(res => {
            // console.log(res)
            if (res.data) {
                let seriesArr = []
                let data = res.data
                let avgTime = {}
                avgTime.sum1 = data.awakenSleepTimeAvg  // 平均清醒时长
                avgTime.sum2 = data.movementSleepTimeAvg    // 平均梦期时长
                avgTime.sum3 = data.shallowSleepTimeAvg // 平均浅睡时长
                avgTime.sum4 = data.deepSleepTimeAvg    // 平均深睡时长
                let sleepScoreAvg = data.sleepScoreAvg  // 平均分数
                let sleepTimeAvg = data.sleepTimeAvg  // 平均睡眠时长
                let sensorDataInfo = data.sensorDataInfo  // 数据
                let arr = util.getWeekList(weekRing)
                let arrData = []
                let chartXData = []
                let deep = []
                let shallow = []
                let movement = []
                let awaken = []
                for (let j = 0; j < arr.length; j++) {
                    const ele = arr[j]
                    let text = `${ele.data.substring(5)}\n周${ele.week}`
                    deep.push(null)
                    shallow.push(null)
                    movement.push(null)
                    awaken.push(null)
                    chartXData.push(text)
                    arrData.push(ele.data.replace(/\./g, ""))
                }
                for (let i = 0; i < sensorDataInfo.length; i++) {
                    const ele = sensorDataInfo[i];
                    let { deepSleepTime, shallowSleepTime, movementSleepTime, awakenSleepTime, sleepTime, deviceDate } = ele
                    let index = arrData.indexOf(deviceDate)
                    deep[index] = Number(deepSleepTime / 60).toFixed(1)
                    shallow[index] = Number(shallowSleepTime / 60).toFixed(1)
                    movement[index] = Number(movementSleepTime / 60).toFixed(1)
                    awaken[index] = Number(awakenSleepTime / 60).toFixed(1)
                }
                let newObj = {
                    sum1: awaken,
                    sum2: movement,
                    sum3: shallow,
                    sum4: deep
                }
                for (let k = 0; k < list.length; k++) {
                    const ele = list[k];
                    let obj = {
                        name: ele.value,
                        color: ele.color,
                        type: 'bar',
                        stack: 'Ad',
                        barWidth: arrData.length > 10 ? 5 : 10,
                        itemStyle: {
                            borderRadius: ele.type == "sum1" ? [5, 5, 0, 0] : ele.type == "sum4" ? [0, 0, 5, 5] : 0,
                        },
                        data: newObj[ele.type],
                        markLine: {
                            silent: true,
                            label: {
                                formatter: `平均睡眠时长\n${util.secToTime(sleepTimeAvg / 60)}`,
                                position: 'insideEndTop',
                                color: 'rgba(255,255,255,0.4)',
                                fontSize: "12",
                            },
                            symbol: "none",
                            lineStyle: {
                                color: "#fff",
                                // type: "solid",
                            },
                            data: [
                                {
                                    yAxis: Number(sleepTimeAvg / 3600).toFixed(1)
                                }
                            ]
                        }
                    }
                    seriesArr.push(obj)
                }
                commit("changeData", {
                    avgTime,
                    sleepScoreAvg,
                    sleepTimeAvg,
                    seriesArr,
                    chartXData,
                })
            } else {
                let seriesArr = []
                for (let k = 0; k < list.length; k++) {
                    const ele = list[k];
                    let obj = {
                        name: ele.value,
                        color: ele.color,
                        type: 'bar',
                        stack: 'Ad',
                        // barWidth: 10,
                        data: [],
                        markLine: {
                            data: []
                        }
                    }
                    seriesArr.push(obj)
                }
                commit("changeData", {
                    avgTime: {
                        sum1: 0,
                        sum2: 0,
                        sum3: 0,
                        sum4: 0
                    },
                    sleepScoreAvg: 0,
                    sleepTimeAvg: 0,
                    seriesArr,
                    chartXData: ['一', '二', '三', '四', '五', '六', '日']
                })
            }
        }).catch(e => {
            console.error("报错：", e)
            let seriesArr = []
            for (let k = 0; k < list.length; k++) {
                const ele = list[k];
                let obj = {
                    name: ele.value,
                    color: ele.color,
                    type: 'bar',
                    stack: 'Ad',
                    barWidth: 10,
                    data: [],
                    markLine: {
                        data: []
                    }
                }
                seriesArr.push(obj)
            }
            commit("changeData", {
                avgTime: {
                    sum1: 0,
                    sum2: 0,
                    sum3: 0,
                    sum4: 0
                },
                sleepScoreAvg: 0,
                sleepTimeAvg: 0,
                seriesArr,
                chartXData: ['一', '二', '三', '四', '五', '六', '日']
            })
        })
    }
}
