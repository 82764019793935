const weeks = ["星期日","星期一","星期二","星期三","星期四","星期五","星期六",]


const changeNum = val => {
	return val >= 10 ? val : "0" + String(Number(val))
}

const judgeType = (s) => {
	// 函数返回数据的具体类型
	return Object.prototype.toString.call(s).slice(8,-1);
}

const getCurrtyDate = (currtyDay=null) => {
    let data = null
    if(!!currtyDay) {
        if(judgeType(currtyDay) === "String") {
            data = new Date(currtyDay.replace(/\./g,"/").replace(/\-/g,"/"))
        }else {
            data = new Date(currtyDay)
        }
    }else {
        data = new Date()
    }
    let y = data.getFullYear()
    let m = data.getMonth() + 1
    let d = data.getDate()
    let w = weeks[data.getDay()]

    return [changeNum(y),changeNum(m),changeNum(d),w]
}
const getCurrtyMonth = (currtyDay=null) => {
    let data = !!currtyDay ? new Date(currtyDay.replace(/\./g,"/").replace(/\-/g,"/")) : new Date()
    let currtyYear = new Date().getFullYear()
    let y = data.getFullYear()
    let m = data.getMonth() + 1
    if(y!=currtyYear) {
        return y + "年" + m + "月"
    }else {
        return m + "月"
    }
}

const getTodayDate = (currtyDay=null) => {
    let data = getCurrtyDate(currtyDay)
    let y = data[0]
    let m = data[1]
    let d = data[2]
    let w = data[3]

    return y + "." + m + "." + d + ' ' + w
}
const pre = (currtyDay) => {
    let data = new Date(currtyDay.replace(/\./g,"/").replace(/\-/g,"/"))
    data.setDate(data.getDate() - 1)
    return getTodayDate(data)
}
const next = (currtyDay) => {
    let data = new Date(currtyDay.replace(/\./g,"/").replace(/\-/g,"/"))
    data.setDate(data.getDate() + 1)
    return getTodayDate(data)
}

const getWeekRing = (currtyDay=null) => {
    let obj = {}
    let data = null
    if(!!currtyDay) {
        if(judgeType(currtyDay) === "String") {
            data = new Date(currtyDay.replace(/\./g,"/").replace(/\-/g,"/"))
        }else {
            data = new Date(currtyDay)
        }
    }else {
        data = new Date()
    }
    let w = data.getDay() == 0 ? 7 : data.getDay()
    let start = data.setDate(data.getDate() - w + 1)
    let end = data.setDate(data.getDate() + 6)
    let startData = getCurrtyDate(start)
    let endData = getCurrtyDate(end)
    startData.pop()
    endData.pop()
    obj.start = startData.join(".")
    obj.end = endData.join(".")
    return obj
}

const getWeekList = (ring, type='week') => {
    let arr = []
    if(ring.constructor === Object && Object.getOwnPropertyNames(ring).length !== 0) {
        let {start, end} = ring
        let startDay = Number(start.replace(/\./g,"").substring(6))
        let endDay = Number(end.replace(/\./g,"").substring(6))
        let data = new Date(start.replace(/\./g,"/").replace(/\-/g,"/"))
        if(startDay > endDay) {
            for (let i = 0; i < 7; i++) {
                let count = i == 0 ? 0 : 1
                data.setDate(data.getDate() + count)
                let d = getTodayDate(data).split(" ")
                arr.push({
                    data: d[0],
                    week: d[1].replace("星期","")
                })
            }
        }else {
            for (let i = startDay; i <= endDay; i++) {
                data.setDate(i)
                let d = getTodayDate(data).split(" ")
                arr.push({
                    data: d[0],
                    week: d[1].replace("星期","")
                })
            }
        }
    }
    return arr
}
const secToArr = (val) => {
    if (val == "--") {
        return ["--", "--", "--"]
    }
    val = val || 0
    let h = 0
    let m = 0
    let s = 0
    if (val >= 60) {
        m = parseInt(val / 60)
        s = parseInt(val % 60)
        if (m >= 60) {
            h = parseInt(m / 60)
            m = parseInt(m % 60)
        }
    }
    h = h < 10 ? "0" + h : h
    m = m < 10 ? "0" + m : m
    s = s < 10 ? "0" + s : s
    return [h, m, s]
}
const secToTime = (val) => {
    if (val == "--") return val
    let timeArr = secToArr(val * 60)
    if (Number(timeArr[0]) == 0) {
        return Number(timeArr[1]) + "分"
    } else {
        return (timeArr[0]) + "小时" + (timeArr[1]) + "分"
    }
}

export default {
    getCurrtyDate,
    getWeekRing,
    getTodayDate,
    pre,
    next,
    getCurrtyMonth,
    getWeekList,
    secToTime,
    secToArr
}