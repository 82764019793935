import { createApp } from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import 'lib-flexible/flexible.js'
import monitor from "./common/point-monitor.js";
// 导入所有组件。
import Vant from 'vant';
import 'vant/lib/index.css';
import { Lazyload } from 'vant';
import * as echarts from 'echarts'
// 国际化
import i18n from './locals'
import routerWebview from './common/webviewRouter.js'
const app = createApp(App) // 创建实例
// 全局定义
console.log("当前开发环境：",process.env.VUE_APP_MODE)
// console.log("当前请求地址：",process.env.VUE_APP_API_URL)

app.config.globalProperties.$C = monitor.clickMonitor;
app.config.globalProperties.$R = routerWebview;
app.config.globalProperties.$echarts = echarts;
app.use(router)
app.use(store)
app.use(Vant)
app.use(Lazyload, {
    lazyComponent: true,
});
app.use(i18n)

app.mount('#app')
// createApp(App).mount('#app')
