export default {
    common: {
        confirm: "确认",
        cancel: "取消"
    },
    home: {
        detection: "高级检测",
        detection_explain: "准确率95%以上",
        improve: "改善打鼾",
        improve_explain: "及时干预打鼾",
        pop_science: "打鼾/OSA科普",
        "subtitle_one": "了解打鼾",
		"subtitle_one_dec": "为什么会打鼾？伤身体吗？",
		"subtitle_two": "改善鼾声",
		"subtitle_two_dec": "如何做能改善打鼾呢？",
    },
    service: {
        title: "服务订阅",
        detection_btn: "了解服务",
        subtitle: "改善打鼾/预防OSA",
        subtitle_right: "每天低至",
        subtitle_unit: "¥",
        subtitle_price: "3.88",
        subtitle_extra: "",
        subtitle_serve: "服务详情"
    },
    detail: {
        title: "了解服务详情",
        title2: "科普详情",
        subtitle_one: "留下您的联系方式",
        subtitle_otwo: "专业顾问致电沟通",
        contact: "联系方式",
        contact_placeholder: "请输入您的手机号",
        contact_error: "请输入正确的手机号码",
        time: "联系时间",
        time_placeholder: "顾问将于该时间段内致电",
        time_error: "请选择联系时间",
        time_title: "时间选择",
        message: "留言",
        message_placeholder: "请输入留言/备注",
        submit: "提交",
        picker_left1: "不限制",
        picker_left2: "非工作日",
        picker_left3: "工作日",
        picker_all: "全天",
    }
}