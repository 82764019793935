export default {
    common: {
        confirm: "confirm",
        cancel: "cancel"
    },
    home: {
        detection: "Advanced Detection",
        detection_explain: "The accuracy rate is over 95%",
        improve: "Improve Snoring",
        improve_explain: "Intervene snoring in time",
        pop_science: "Snoring /OSA Popular Science",
        "subtitle_one": "Know about snoring",
		"subtitle_one_dec": "Is snoring  harmful to your health?",
		"subtitle_two": "Reduce snoring",
		"subtitle_two_dec": "How to reduce snoring?",
    },
    service: {
        title: "Service Subscription",
        detection_btn: "Understanding Services",
        subtitle: "Improve snoring/prevent OSA",
        subtitle_right: "As low as ",
        subtitle_unit: "¥ ",
        subtitle_price: "3.88 ",
        subtitle_extra: "per day",
        subtitle_serve: "Service details"
    },
    detail: {
        title: "Service Details",
        title2: "Popular Science Details",
        subtitle_one: "Leave your contact information",
        subtitle_otwo: "Professional consultant call communication",
        contact: "Phone",
        contact_placeholder: "Please enter your mobile phone number",
        contact_error: "Please enter the correct mobile phone number",
        time: "Time",
        time_placeholder: "The consultant will call during that time",
        time_error: "Please select a contact time",
        time_title: "Select Time",
        message: "Message",
        message_placeholder: "Please enter your message/remarks",
        submit: "Submit",
        picker_left1: "Unlimited",
        picker_left2: "Weekend",
        picker_left3: "Weekday",
        picker_all: "All day",
    }
}