
export default {
    heartValue: "--",
    breatheValue: "--",
    heartData: [],
    breatheData: [],
    heartValue1: "--",
    breatheValue1: "--",
    heartData1: [],
    breatheData1: [],
    deviceName: "S02S1_KY01",
    chooseType: "day",
    currtyData: "",
    // 日报
    bedtime: "--",
    wakeup: "--",
    expValue: "--",
    sleepTime: "--",
    heartUpper: "--",
    heartLower: "--",
    breatheUpper: "--",
    breatheLower: "--",
    breatheChartData: [],
    heartChartData: [],
    exponentChart: [],
    sumTime: {
        sum1: 0,
        sum2: 0,
        sum3: 0,
        sum4: 0
    },
    // 周/月报
    avgTime: {
        sum1: 0,
        sum2: 0,
        sum3: 0,
        sum4: 0
    },
    sleepScoreAvg: "--",
    sleepTimeAvg: "--",
    seriesArr: [
        {
            name: '',
            type: 'bar',
            stack: 'Ad',
            data: []
        },
    ],
    chartXData: ['一', '二', '三', '四', '五', '六', '日']
}
